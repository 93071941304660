import React from "react"
import ChoiceQuiz from "../../../components/layouts/choice_quiz"
const verbs = require("../../../components/japanese/data/transitive.json")

const instruction = "Choose whether the verb is transitive or intransitive.";
const footer = <div>Confused? Check out <a href="https://www.tofugu.com/japanese/transitive-intransitive-verbs/">this page!</a></div>

var data = {
  "choices": ["Transitive", "Intransitive"],
  "questions": {"Transitive": [], "Intransitive": []}
}
verbs.forEach(v => {
  data.questions.Transitive.push(v.t.verb)
  data.questions.Intransitive.push(v.i.verb)
});

class TransOrIntrans extends React.Component  {
    handleClick = (answer) => {
      alert(answer);
    }
    render() {
        return (
          <ChoiceQuiz title="Transitive or Intransitive" data={data} instruction={instruction} footer={footer}></ChoiceQuiz>
        );
      }
}

export default TransOrIntrans